<template lang="pug">
.page
  el-card.tableCard(v-loading="loading")
    template(#header)
      span {{evaluationInfo.name}} 的报告，当前共 {{list.length}} 条
      .rightAction(style="float:right")
        el-button(type="success" size="mini" icon="el-icon-plus"  @click="handleAdd") 添加新报告
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="category" label="分组")
      el-table-column(label="内容")
        template(#default="scope")
          el-button(type="primary" plain @click="handleOpenContent(scope.row)") 内容
      el-table-column(fixed="right" label="操作" width="200")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)") 编辑
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain) 删除

  el-dialog(v-model="isShowEditDialog" width="850px")
    el-form(label-width="6em")
      el-form-item(label="id" v-if="editFormData._id")
        el-input(v-model="editFormData._id" disabled)
      el-form-item(label="分类")
        el-input(v-model="editFormData.category")
      el-form-item(label="内容")
        com-editor(v-model="editFormData.content" type="full" style="width:700px")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import comEditor from '@/component/editor/index.vue'
import { ElMessageBox } from 'element-plus'

const route = useRoute()
const { fetch, router, message } = inject('global')

const loading = ref(false)
const list = ref([])
const evaluationInfo = ref({
  name: ''
})

const isShowEditDialog = ref(false)
const editFormData = ref({
  _id: '',
  category: '',
  content: ''
})

function handleOpenContent(val) {
  ElMessageBox.alert(val.content, val.category, {
    dangerouslyUseHTMLString: true
  })
}

function initData() {
  list.value = []
  getList()
}
function getList() {
  loading.value = true
  let params = {
    sort: 'order',
    evaluation: route.params.id
  }
  fetch
    .get('/evaluation/result', { params })
    .then(res => {
      list.value = res.list
      evaluationInfo.value = res.evaluationInfo
    })
    .finally(() => {
      loading.value = false
    })
}

function handleDelete(val) {
  fetch.delete(`/evaluation/result/${val._id}`).then(() => {
    initData()
  })
}
function handleAdd() {
  editFormData.value = {
    _id: '',
    title: '',
    desc: '',
    answerList: [{ text: '', value: '' }]
  }
  isShowEditDialog.value = true
}
function handleEdit(val) {
  editFormData.value = val
  isShowEditDialog.value = true
}
function handleAddAnswerItem() {
  editFormData.value.answerList.push({ text: '', value: '' })
}
function handleSubmitEdit() {
  fetch
    .put(`/evaluation/result`, {
      ...editFormData.value,
      evaluation: route.params.id
    })
    .then(res => {
      message.success(res?.msg || '保存成功')
      isShowEditDialog.value = false
      initData()
    })
    .catch(err => {
      message.error(err?.msg || err || '系统异常')
    })
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.tableCard {
  .el-table {
    min-height: 600px;
  }
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
